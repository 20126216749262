import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import clsx from "clsx";

import IsConnected from "./IsConnected";
import Name from "./Name";
import SubType from "./SubType";
import ContentType from "./ContentType";
import Capacity from "./Capacity";
import Unit from "./Unit";
import FillLimit from "./FillLimit";
import FillHeight from "./FillHeight";
import Threshold from "./Threshold";
import GeometrySelect from "./Geometry";
import ContentDensity from "./ContentDensity";
import { useContentDensities } from "../../../../../../domain/contentDensity";
import GroundClearance from "./GroundClearance";

const useStyles = makeStyles(theme => ({
  row: {
    paddingBottom: 6
  },
  column: {
    paddingLeft: 12
  },
  color: {
    color: theme.palette.common.white,
    justifyContent: "center",
    alignItems: "center"
  },
  colorBox: {
    width: 30,
    height: 30,
    fontSize: 13,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  isConnected: {
    textAlign: "center"
  },
  capacityWrapper: {
    display: "flex"
  },
  capacity: {
    flexGrow: 1
  },
  unit: {
    flex: "0 0 75px"
  }
}));

const AmpSensor = ({ sensor, prefix, setFieldValue }) => {
  const classes = useStyles();
  const theme = useTheme();
  const contentDensities = useContentDensities();

  const unitOptions = ["l", "kg"];
  const subTypeOptions = ["tank", "silo"];

  const updateContentDensity = newContentType => {
    const newDensity = contentDensities.find(
      elem => elem.name === newContentType
    );
    if (newDensity === undefined) {
      return;
    }

    setFieldValue(`${prefix}.contentDensity`, newDensity.value);
  };

  return (
    <tr className={classes.row}>
      <td className={classes.color}>
        <div
          className={classes.colorBox}
          style={{
            backgroundColor: theme.custom.colors.sensors[sensor.sequence]
          }}
        >
          {sensor.sequence}
        </div>
      </td>
      <td className={clsx(classes.isConnected, classes.column)}>
        <IsConnected
          isConnected={sensor.isConnected}
          setFieldValue={setFieldValue}
          prefix={prefix}
        />
      </td>
      <td className={classes.column}>
        <SubType prefix={prefix} options={subTypeOptions} />
      </td>
      <td className={clsx(classes.name, classes.column)}>
        <Name prefix={prefix} />
      </td>
      <td className={classes.column}>
        <ContentType
          contentType={sensor.contentType}
          setFieldValue={setFieldValue}
          onChangeHandler={updateContentDensity}
          prefix={prefix}
        />
      </td>
      <td className={classes.column}>
        <ContentDensity setFieldValue={setFieldValue} prefix={prefix} />
      </td>
      <td className={classes.column}>
        <GeometrySelect
          geometry={sensor.geometry}
          setFieldValue={setFieldValue}
          prefix={prefix}
        />
      </td>
      <td className={classes.column}>
        <FillHeight prefix={prefix} />
      </td>
      <td className={classes.column}>
        <GroundClearance prefix={prefix} />
      </td>
      <td className={classes.column}>
        <div className={classes.capacityWrapper}>
          <div className={classes.capacity}>
            <Capacity prefix={prefix} />
          </div>
          <div className={classes.unit}>
            <Unit prefix={prefix} options={unitOptions} />
          </div>
        </div>
      </td>
      <td className={classes.column}>
        <FillLimit prefix={prefix} />
      </td>
      <td className={classes.column}>
        <Threshold prefix={prefix} />
      </td>
    </tr>
  );
};

export default AmpSensor;
