import React from "react";
import { Grid, Box } from "@material-ui/core";

import HardwareVersion from "./HardwareVersion";
import PublicUrlAccessEnabled from "./PublicUrlAccessEnabled";
import { ROLES, useAuth } from "../../../../../../auth";

const Device = React.memo(() => {
  const { user, hasRole } = useAuth();
  return (
    <Grid container>
      <Grid item xs={12}>
        <HardwareVersion />
      </Grid>

      {hasRole(user, ROLES.MASTER) && (
        <Grid item xs={12}>
          <Box paddingTop={1}>
            <PublicUrlAccessEnabled />
          </Box>
        </Grid>
      )}
    </Grid>
  );
});

export default Device;
