import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";

import I18nText from "../I18nText";
import AlertButton from "./AlertButton";

const DeleteButton = ({ onClick, disabled, fullWidth, iconOnly = false }) => (
  <AlertButton
    disabled={disabled}
    icon={<DeleteIcon />}
    onClick={onClick}
    fullWidth={fullWidth}
  >
    {!iconOnly && <I18nText>actions.delete</I18nText>}
  </AlertButton>
);

export default DeleteButton;
