import React from "react";
import { FullWidthTextField } from "../../../elements/FormField";

import I18nText from "../../../elements/I18nText";

const IotDeviceForm = () => {
  return (
    <FullWidthTextField
      name="thingName"
      variant="outlined"
      required
      label={<I18nText>devices.common.thingName</I18nText>}
      inputProps={{ maxLength: 128, autoComplete: "off" }}
    />
  );
};

export default IotDeviceForm;
