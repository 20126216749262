import React, { useState, useEffect } from "react";
import { DatePicker } from "@material-ui/pickers";
import { Grid, TextField, MenuItem, makeStyles, Box } from "@material-ui/core";
import UpdateIcon from "@material-ui/icons/Update";

import I18nText from "../../elements/I18nText";
import { PrimaryButton } from "../../elements/Button";
import {
  MESSAGE_TYPE,
  MESSAGE_DIRECTION,
  MESSAGE_MODE
} from "../../domain/message";
import Section from "../../elements/Section";
import SectionHeader from "../../elements/SectionHeader";
import { ALL_VALUE, CONTEXT_DEFAULT } from "./MessagesContainer";
import DeviceSelect from "../../elements/FormField/DeviceSelect";
import { PERMISSIONS } from "../../auth";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%"
  }
}));

const Filter = ({ context, values, onApply, onRefresh, queryComponent }) => {
  const classes = useStyles();
  const [filter, setFilter] = useState({ ...values });

  useEffect(() => {
    setFilter(values);
  }, [values]);

  const setFilterValue = (key, value) => {
    setFilter({
      ...filter,
      [key]: value
    });
  };

  return (
    <Section>
      <SectionHeader>
        <I18nText>messages.sections.filter</I18nText>
      </SectionHeader>

      <Grid container spacing={2}>
        {context === CONTEXT_DEFAULT && (
          <Grid item xs={12} sm={4} md={3}>
            <DeviceSelect
              value={filter.device}
              name="device"
              excludedIds={[]}
              permission={PERMISSIONS.VIEW_MESSAGING}
              label={<I18nText>messages.common.device</I18nText>}
              onUpdate={device => setFilterValue("device", device)}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={4} md={3}>
          <TextField
            label={<I18nText>messages.common.direction</I18nText>}
            classes={classes}
            size="small"
            select
            variant="outlined"
            value={filter.direction}
            onChange={e => setFilterValue("direction", e.target.value)}
          >
            <MenuItem value={ALL_VALUE}>
              <I18nText>messages.directions.all</I18nText>
            </MenuItem>
            <MenuItem value={MESSAGE_DIRECTION.IN}>
              <I18nText>messages.directions.in</I18nText>
            </MenuItem>
            <MenuItem value={MESSAGE_DIRECTION.OUT}>
              <I18nText>messages.directions.out</I18nText>
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField
            label={<I18nText>messages.common.status</I18nText>}
            classes={classes}
            size="small"
            select
            variant="outlined"
            value={filter.status}
            onChange={e => setFilterValue("status", e.target.value)}
          >
            <MenuItem value={ALL_VALUE}>
              <I18nText>messages.statuses.all</I18nText>
            </MenuItem>
            <MenuItem value="ok">
              <I18nText>messages.statuses.ok</I18nText>
            </MenuItem>
            <MenuItem value="error">
              <I18nText>messages.statuses.error</I18nText>
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField
            label={<I18nText>messages.common.type</I18nText>}
            classes={classes}
            size="small"
            select
            variant="outlined"
            value={filter.type}
            onChange={e => setFilterValue("type", e.target.value)}
          >
            <MenuItem value={ALL_VALUE}>
              <I18nText>messages.types.all</I18nText>
            </MenuItem>
            <MenuItem value={MESSAGE_TYPE.SMS}>
              <I18nText>messages.types.sms</I18nText>
            </MenuItem>
            <MenuItem value={MESSAGE_TYPE.NET}>
              <I18nText>messages.types.net</I18nText>
            </MenuItem>
            <MenuItem value={MESSAGE_TYPE.IOT}>
              <I18nText>messages.types.iot</I18nText>
            </MenuItem>
            <MenuItem value={MESSAGE_TYPE.MAIL}>
              <I18nText>messages.types.mail</I18nText>
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField
            label={<I18nText>messages.common.mode</I18nText>}
            classes={classes}
            size="small"
            select
            variant="outlined"
            value={filter.mode}
            onChange={e => setFilterValue("mode", e.target.value)}
          >
            <MenuItem value={ALL_VALUE}>
              <I18nText>messages.modes.all</I18nText>
            </MenuItem>
            <MenuItem value={MESSAGE_MODE.SYSTEM_TO_DEVICE}>
              <I18nText>messages.modes.systemToDevice</I18nText>
            </MenuItem>
            <MenuItem value={MESSAGE_MODE.DEVICE_TO_SYSTEM}>
              <I18nText>messages.modes.deviceToSystem</I18nText>
            </MenuItem>
            <MenuItem value={MESSAGE_MODE.SYSTEM_TO_EXTERNAL}>
              <I18nText>messages.modes.systemToExternal</I18nText>
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <DatePicker
            classes={classes}
            size="small"
            variant="dialog"
            inputVariant="outlined"
            ampm={false}
            label={<I18nText>messages.filter.dateRangeFrom</I18nText>}
            value={filter.from}
            onChange={value => setFilterValue("from", value)}
            disableFuture={true}
            format="dd.MM.yyyy"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <DatePicker
            classes={classes}
            size="small"
            variant="dialog"
            inputVariant="outlined"
            ampm={false}
            label={<I18nText>messages.filter.dateRangeTo</I18nText>}
            value={filter.to}
            onChange={value => setFilterValue("to", value)}
            disableFuture={true}
            format="dd.MM.yyyy"
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Box marginRight={1}>{queryComponent}</Box>
            <Box marginRight={1}>
              <PrimaryButton onClick={onRefresh} icon={<UpdateIcon />} />
            </Box>
            <PrimaryButton onClick={() => onApply(filter)}>
              <I18nText>messages.actions.applyFilter</I18nText>
            </PrimaryButton>
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
};

export default Filter;
