import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  Redirect
} from "react-router-dom";

import { RoleProtectedRoute } from "../ProtectedRoute";
import { useApi } from "../../api";
import Table from "./Table";
import List from "./List";
import Pagination from "../../elements/Pagination";
import SearchTermFilter from "./SearchTermFilter";
import { useWhitelabel } from "../../domain/whitelabel";
import Export from "../Export";
import ConfigDialog from "./Config/ConfigDialog";
import BlockQueries from "../BlockQueries";
import RefDateQueries from "../RefDateQueries";
import Create from "../Devices/Create";
import PageSelect from "../../elements/PageSelect";
import { ROLES } from "../../auth";
import { useDeviceIds } from "../../domain/device";

const ListContainer = () => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const api = useApi();
  const {
    whitelabelConfig,
    whitelabelConfigLoaded,
    updateWhitelabelConfig
  } = useWhitelabel();
  const { reload: reloadDeviceIds } = useDeviceIds();
  const [devices, setDevices] = useState([]);
  const [sort, setSort] = useState({});
  const [totalCount, setTotalCount] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableColumns, setTableColumns] = useState([]);
  const [sensorSettings, setSensorSettings] = useState({});
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(null);
  const [readyToLoadDevices, setReadyToLoadDevices] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [isUpdating, setUpdating] = useState(true);

  const groupByUser = whitelabelConfig["ui.devices.groupByUser"];

  const handleSetSearchTerm = term => {
    if (term === searchTerm) {
      return;
    }

    setPage(1);
    setSearchTerm(term);
  };

  const handleSelectPerPage = perPage => {
    const value = parseInt(perPage, 10);

    updateWhitelabelConfig("ui.devices.list.perPage", value);
    setPerPage(value);
    setPage(1);
  };

  const handleShowPercentages = value => {
    updateWhitelabelConfig("ui.devices.list.sensors.showPercentages", value);

    setSensorSettings(sensorSettings => ({
      ...sensorSettings,
      showPercentages: value
    }));
  };

  const handleUpdateSort = by => {
    let desc = false;
    if (sort.by === by) {
      if (sort.desc === false) {
        desc = true;
      }
    }

    updateWhitelabelConfig("ui.devices.sort.by", by);
    updateWhitelabelConfig("ui.devices.sort.desc", desc);
    reloadDeviceIds();

    setSort({
      by,
      desc
    });
  };

  useEffect(() => {
    setPage(1);
  }, [groupByUser]);

  useEffect(() => {
    if (!whitelabelConfigLoaded) {
      return;
    }

    if (
      sort.by !== whitelabelConfig["ui.devices.sort.by"] ||
      sort.desc !== whitelabelConfig["ui.devices.sort.desc"]
    ) {
      setSort({
        by: whitelabelConfig["ui.devices.sort.by"],
        desc: whitelabelConfig["ui.devices.sort.desc"]
      });
    }

    setPerPage(whitelabelConfig["ui.devices.list.perPage"]);

    setTableColumns(whitelabelConfig["ui.devices.list.columns"]);
    setSensorSettings({
      showNames: whitelabelConfig["ui.devices.list.sensors.showNames"],
      showVisuals: whitelabelConfig["ui.devices.list.sensors.showVisuals"],
      showUnits: whitelabelConfig["ui.devices.list.sensors.showUnits"],
      showPercentages:
        whitelabelConfig["ui.devices.list.sensors.showPercentages"],
      showTemperatures:
        whitelabelConfig["ui.devices.list.sensors.showTemperatures"]
    });

    setReadyToLoadDevices(true);
  }, [whitelabelConfig, sort.by, sort.desc, whitelabelConfigLoaded]);

  useEffect(() => {
    if (!readyToLoadDevices) {
      return;
    }

    let query = "";

    if (sort) {
      query += `&sort=${sort.by}:${sort.desc ? "desc" : "asc"}`;
    }

    if (page) {
      query += `&page=${page}`;
    }

    if (perPage) {
      query += `&per_page=${perPage}`;
    }

    if (searchTerm) {
      query += `&search_term=${encodeURI(searchTerm)}`;
    }

    if (groupByUser) {
      query += "&grouped=1";
    }

    setUpdating(true);
    api.get(`/v2/devices?${query}`).then(response => {
      setDevices(response.data);
      setTotalCount(response.headers["x-total-count"]);
      setLoading(false);
      setUpdating(false);
    });
  }, [api, sort, perPage, page, searchTerm, groupByUser, readyToLoadDevices]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Switch>
        <RoleProtectedRoute role={ROLES.ADMIN} path={`${url}/new`}>
          <Create onClose={() => history.push("/devices")} />
        </RoleProtectedRoute>
        <Route path={`${url}/config`}>
          <ConfigDialog onClose={() => history.push("/devices")} />
        </Route>
        <Route path={`${url}/export`}>
          <Export onClose={() => history.push("/devices")} />
        </Route>
        <Route path={`${url}/block-queries`}>
          <BlockQueries onClose={() => history.push("/devices")} />
        </Route>
        <RoleProtectedRoute role={ROLES.USER} path={`${url}/ref-date-queries`}>
          <RefDateQueries onClose={() => history.push("/devices")} />
        </RoleProtectedRoute>
        <Route>
          <Redirect to={url} />
        </Route>
      </Switch>

      <List
        onShowExport={() => history.push("/devices/export")}
        onShowConfig={() => history.push("/devices/config")}
        onShowBlockQueries={() => history.push("/devices/block-queries")}
        onShowRefDateQueries={() => history.push("/devices/ref-date-queries")}
        onShowCreate={() => history.push("/devices/new")}
        paginationComponent={
          <Pagination
            totalCount={totalCount}
            perPage={perPage}
            page={page}
            onSelectPage={page => setPage(page)}
            allowPerPageSelect={false}
          />
        }
        pageSelectComponent={
          <PageSelect
            perPage={perPage}
            perPageOptions={[10, 25, 50, 100]}
            onSelectPerPage={handleSelectPerPage}
          />
        }
        tableComponent={
          <Table
            devices={devices}
            sort={sort}
            onUpdateSort={handleUpdateSort}
            groupByUser={groupByUser}
            isUpdating={isUpdating}
            tableColumns={tableColumns}
            sensorSettings={sensorSettings}
            onTogglePercentages={() =>
              handleShowPercentages(!sensorSettings.showPercentages)
            }
          />
        }
        searchComponent={
          <SearchTermFilter onSetSearchTerm={handleSetSearchTerm} />
        }
      />
    </>
  );
};

export default ListContainer;
