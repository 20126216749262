import React from "react";
import { Provider } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { SnackbarProvider } from "notistack";
import { CookiesProvider } from "react-cookie";

import "./i18n/i18n";
import { store } from "./store";
import GlobalCss from "./themes/GlobalCss";
import { RoleProtectedRoute } from "./components/ProtectedRoute";
import DevicesContainer from "./components/Devices";
import ImpersonationSnackbar from "./components/ImpersonationSnackbar";
import Users from "./components/Users";
import System from "./components/System";
import Settings from "./components/Settings";
import Contacts from "./components/Contacts";
import Login from "./components/Login";
import Messages from "./components/Messages";
import WhitelabelProvider from "./context/WhitelabelProvider";
import DeviceIdsProvider from "./context/DeviceIdsProvider";
import { ApiProvider } from "./api";
import { AuthProvider, ROLES } from "./auth";
import { LocaleProvider } from "./i18n";
import ResourcesProvider from "./context/ResourcesProvider";
import MuiPickersLocalizedProvider from "./context/MuiPickersLocalizedProvider";

const App = () => (
  <Sentry.ErrorBoundary fallback="Es ist ein Fehler aufgetreten. Bitte laden Sie die Seite erneut.">
    <Provider store={store}>
      <CookiesProvider>
        <LocaleProvider>
          <MuiPickersLocalizedProvider>
            <Router>
              <ApiProvider>
                <AuthProvider>
                  <WhitelabelProvider>
                    <ResourcesProvider>
                      <DeviceIdsProvider>
                        <SnackbarProvider>
                          <GlobalCss />
                          <CssBaseline />
                          <ImpersonationSnackbar />
                          <Switch>
                            <RoleProtectedRoute
                              role={ROLES.DEMO}
                              name="devices"
                              path="/devices"
                            >
                              <DevicesContainer />
                            </RoleProtectedRoute>
                            <RoleProtectedRoute
                              role={ROLES.ADMIN}
                              name="users"
                              path="/users"
                            >
                              <Users />
                            </RoleProtectedRoute>
                            <RoleProtectedRoute
                              role={ROLES.USER}
                              name="settings"
                              path="/settings"
                            >
                              <Settings />
                            </RoleProtectedRoute>
                            <RoleProtectedRoute
                              role={ROLES.USER}
                              name="contacts"
                              path="/contacts"
                            >
                              <Contacts />
                            </RoleProtectedRoute>
                            <RoleProtectedRoute
                              role={ROLES.MASTER}
                              name="system"
                              path="/system"
                            >
                              <System />
                            </RoleProtectedRoute>
                            <RoleProtectedRoute
                              role={ROLES.USER}
                              name="messages"
                              path="/messages"
                            >
                              <Messages />
                            </RoleProtectedRoute>
                            <Route path="/login" component={Login} />
                            <Route>
                              <Redirect to="/devices" />
                            </Route>
                          </Switch>
                        </SnackbarProvider>
                      </DeviceIdsProvider>
                    </ResourcesProvider>
                  </WhitelabelProvider>
                </AuthProvider>
              </ApiProvider>
            </Router>
          </MuiPickersLocalizedProvider>
        </LocaleProvider>
      </CookiesProvider>
    </Provider>
  </Sentry.ErrorBoundary>
);

export default App;
