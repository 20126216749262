import React from "react";
import ConfigurationForm from "./ConfigurationForm";

import { SIM_CREDITOR_NONE } from "./smsDevice/SimCreditor";
import { useApi } from "../../../../../api";
import {
  createFieldErrorMessage,
  setFieldErrorsFromResponse
} from "../../../../../utils/formErrors";
import {
  TIME_UNITS,
  inMostSuitableTimeUnit,
  valueAndTimeUnitToSeconds
} from "../../../../../utils/timeUtils";
import {
  DEVICE_TYPE,
  DEVICE_SMS_GATEWAY_TYPE,
  DEVICE_CAPABILITIES
} from "../../../../../domain/device";
import { SENSOR_TYPE } from "../../../../../domain/sensor";
import useI18nSnackbar from "../../../../../hooks/useI18nSnackbar";
import { useSmsGateways } from "../../../../../domain/smsGateway";

const ConfigurationContainer = ({ device, onClose }) => {
  const api = useApi();
  const smsGateways = useSmsGateways();
  const { enqueueSnackbar } = useI18nSnackbar();

  const validate = values => {
    const errors = {};

    values.sensors.forEach((sensor, idx) => {
      const sensorErrors = {};

      if (!sensor.isConnected) {
        return;
      }

      if (device.capabilities === DEVICE_CAPABILITIES.AMPERE) {
        if (!sensor.fillHeight && sensor.fillHeight !== 0) {
          sensorErrors.fillHeight = createFieldErrorMessage(
            "fillHeight",
            "required"
          );
        }

        if (!sensor.geometry) {
          sensorErrors.geometry = createFieldErrorMessage(
            "geometry",
            "required"
          );
        }

        if (!sensor.contentDensity) {
          sensorErrors.contentDensity = createFieldErrorMessage(
            "contentDensity",
            "required"
          );
        }
      }

      if (sensor.type === SENSOR_TYPE.PROBE) {
        if (!sensor.capacity && sensor.capacity !== 0) {
          sensorErrors.capacity = createFieldErrorMessage(
            "capacity",
            "required"
          );
        }

        if (!sensor.fillLimit && sensor.fillLimit !== 0) {
          sensorErrors.fillLimit = createFieldErrorMessage(
            "fillLimit",
            "required"
          );
        }

        if (!sensor.threshold && sensor.threshold !== 0) {
          sensorErrors.threshold = createFieldErrorMessage(
            "threshold",
            "required"
          );
        }
      }

      if (Object.keys(sensorErrors).length > 0) {
        if (!errors.sensors) {
          errors.sensors = [];
        }

        errors.sensors[idx] = sensorErrors;
      }
    });

    return errors;
  };

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    const { sensors, ...params } = values;

    params.sensors = sensors.map(sensor => {
      // Unset sensor type, sequence and id for API call
      const { id, sequence, type, ...sensorParam } = sensor;

      return sensorParam;
    });

    if (params.simCreditorId === SIM_CREDITOR_NONE) {
      params.simCreditorId = null;
    }

    if (params.timeInterval) {
      params.timeInterval = valueAndTimeUnitToSeconds(
        params.timeInterval.value,
        params.timeInterval.unit
      );
    }

    if (params.thresholdInterval) {
      params.thresholdInterval = valueAndTimeUnitToSeconds(
        params.thresholdInterval.value,
        params.thresholdInterval.unit
      );
    }

    api
      .put(`/v2/devices/${device.id}/configuration`, params)
      .then(response => {
        enqueueSnackbar("devices.settings.configuration.responses.updated", {
          variant: "success"
        });

        const sentSms = parseInt(response.headers["x-sms-sent"], 10);

        if (sentSms > 0) {
          enqueueSnackbar("devices.settings.configuration.responses.smsSent", {
            variant: "success"
          });
        }

        onClose(true);
      })
      .catch(e => {
        if (!e.response) {
          return;
        }

        setSubmitting(false);
        setFieldErrorsFromResponse(e.response, setFieldError);
      });
  };

  const sensors = device.sensors.map(sensor => {
    let value = {
      id: sensor.id,
      sequence: sensor.sequence,
      type: sensor.type,
      name: sensor.name,
      isConnected: sensor.isConnected,
      subType: sensor.subType,
      unit: sensor.unit
    };

    if (sensor.type === SENSOR_TYPE.PROBE) {
      value = {
        ...value,
        contentType: sensor.contentType,
        fillLimit: sensor.fillLimit,
        capacity: sensor.capacity,
        threshold: sensor.threshold
      };

      if (sensor.temperatureLimitType !== "none") {
        value.temperatureLimitValue = sensor.temperatureLimitValue;
      }

      if (device.capabilities === DEVICE_CAPABILITIES.AMPERE) {
        value.fillHeight = sensor.fillHeight;
        value.contentDensity = sensor.contentDensity;
        value.geometry = sensor.geometry;
        value.groundClearance = sensor.groundClearance || 0;
      }
    }

    return value;
  });

  let values = {
    sensors,
    thresholdSensor: device.thresholdSensor || "first",
    hardwareVersion: device.hardwareVersion,
    publicUrlAccessEnabled: device.publicUrlAccessEnabled || false
  };

  // Values if SMS or IoT
  if (device.type === DEVICE_TYPE.SMS || device.type === DEVICE_TYPE.IOT) {
    // Values for both SMS and IoT
    values = {
      ...values,
      valueInterval: device.valueInterval
    };

    if (device.timeInterval === null) {
      values.timeInterval = {
        value: 0,
        unit: TIME_UNITS.HOUR
      };
    } else {
      values.timeInterval = inMostSuitableTimeUnit(device.timeInterval, [
        TIME_UNITS.HOUR,
        TIME_UNITS.DAY
      ]);
    }

    if (device.thresholdInterval === null) {
      values.thresholdInterval = {
        value: 0,
        unit: TIME_UNITS.HOUR
      };
    } else {
      values.thresholdInterval = inMostSuitableTimeUnit(
        device.thresholdInterval,
        [TIME_UNITS.HOUR, TIME_UNITS.DAY]
      );
    }

    // Values for SMS only
    if (device.type === DEVICE_TYPE.SMS) {
      values.smsHeader = device.smsHeader || "";
      values.simType = device.simType || "";
      values.simCreditorId = device.simCreditorId || SIM_CREDITOR_NONE;
      values.forceSms = false;

      if (device.smsGatewayType === DEVICE_SMS_GATEWAY_TYPE.LIONEL) {
        values.cellNumber = device.ident ? device.ident : "";
        values.simProvider = device.simProvider ? device.simProvider : "";
        values.inboundGatewayId = device.inboundGatewayId;
        values.outboundGatewayId = device.outboundGatewayId;
      }

      if (device.smsGatewayType === DEVICE_SMS_GATEWAY_TYPE.ONCE) {
        values.iccid = device.ident ? device.ident : "";
      }
    }

    // Values for IoT only
    if (device.type === DEVICE_TYPE.IOT) {
      values.thingName = device.ident ? device.ident : "";
    }
  }

  // Values if NET
  if (device.type === DEVICE_TYPE.NET) {
    values = {
      ...values,
      host: device.host || "",
      port: device.port || 0
    };
  }

  return (
    <ConfigurationForm
      capabilities={device.capabilities}
      values={values}
      onClose={onClose}
      showSmsGatewaySelect={smsGateways.length > 1}
      onSubmit={handleSubmit}
      validator={validate}
    />
  );
};

export default ConfigurationContainer;
