import React, { useState } from "react";

import { useApi } from "../../../api";
import {
  setFieldErrorsFromResponse,
  createFieldErrorMessage
} from "../../../utils/formErrors";
import FormDialog from "./FormDialog";
import CreateForm from "./CreateForm";
import { useAuth, ROLES } from "../../../auth";
import { generatePassword } from "../../../utils/stringUtils";
import useI18nSnackbar from "../../../hooks/useI18nSnackbar";

const CreateContainer = ({ parentUser = null, onClose, onCreated }) => {
  const api = useApi();
  const { user, getAssignableRoles } = useAuth();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [password, setPassword] = useState(generatePassword());

  const validate = values => {
    const errors = {};

    if (values.username.length < 4 || values.username.length > 32) {
      errors.username = createFieldErrorMessage("username", "length", {
        min: 4,
        max: 96
      });
    }

    return errors;
  };

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    const params = {
      username: values.username,
      parentUserId: values.parentUser.id,
      role: values.role,
      active: values.active,
      password,
      passwordRepeat: password
    };

    api
      .post("/v2/users", params)
      .then(response => {
        enqueueSnackbar("users.responses.created", { variant: "success" });

        const apiUrl = response.headers.location;
        const newId = apiUrl.substr(apiUrl.lastIndexOf("/") + 1);

        onCreated(newId);
      })
      .catch(e => {
        if (!e.response) {
          return;
        }

        setSubmitting(false);
        setFieldErrorsFromResponse(e.response, setFieldError);
      });
  };

  const handleRegeneratePassword = () => {
    setPassword(generatePassword());
  };

  const roles = getAssignableRoles(user);

  const initialValues = {
    username: "",
    parentUser: {
      id: parentUser ? parentUser.id : user.id,
      label: parentUser
        ? `${parentUser.name} (${parentUser.username})`
        : `${user.name} (${user.username})`
    },
    role: ROLES.USER,
    active: true
  };

  return (
    <FormDialog onClose={onClose}>
      <CreateForm
        roles={roles}
        password={password}
        values={initialValues}
        onClose={onClose}
        onSubmit={handleSubmit}
        validate={validate}
        onRegeneratePassword={handleRegeneratePassword}
      />
    </FormDialog>
  );
};

export default CreateContainer;
