import React from "react";
import MetadataForm from "./MetadataForm";

import { useApi } from "../../../../../api";
import { setFieldErrorsFromResponse } from "../../../../../utils/formErrors";
import { useAuth, PERMISSIONS } from "../../../../../auth";
import useI18nSnackbar from "../../../../../hooks/useI18nSnackbar";

const MetadataContainer = ({ device, onClose }) => {
  const api = useApi();
  const { hasPermission } = useAuth();
  const { enqueueSnackbar } = useI18nSnackbar();

  const enableUserSelect = hasPermission(device.permissions, PERMISSIONS.EDIT);

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    const params = {
      name: values.name,
      address: { ...values.address }
    };

    if (enableUserSelect) {
      params.userId = values.user.id;
    }

    api
      .put(`/v2/devices/${device.id}/metadata`, params)
      .then(() => {
        enqueueSnackbar("devices.settings.metadata.responses.updated", {
          variant: "success"
        });

        onClose(true);
      })
      .catch(e => {
        if (!e.response) {
          return;
        }

        setSubmitting(false);
        setFieldErrorsFromResponse(e.response, setFieldError);
      });
  };

  const values = {
    user: {
      id: device.user.id,
      label: `${device.user.name} (${device.user.username})`
    },
    name: device.name,
    address: {
      ...device.address
    }
  };

  return (
    <MetadataForm
      enableUserSelect={enableUserSelect}
      values={values}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default MetadataContainer;
