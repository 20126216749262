import React, { useState, useEffect } from "react";

import Dialog from "./Dialog";
import { useApi } from "../../../../../api";

import UserForm from "./UserForm";
import FormContainer from "../Form/FormContainer";
import { useAuth } from "../../../../../auth";
import useI18nSnackbar from "../../../../../hooks/useI18nSnackbar";

const CreateContainer = ({ device, onClose }) => {
  const { user: myself } = useAuth();
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [step, setStep] = useState("user");
  const [excludeList, setExcludeList] = useState([]);

  useEffect(() => {
    setLoading(true);

    api.get(`/v2/devices/${device.id}/aces`).then(response => {
      // Set own user and all users with existing ACEs on exlude list
      setExcludeList([myself.id, ...response.data.map(ace => ace.userId)]);
      setLoading(false);
    });
  }, [api, device, myself.id]);

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    const params = {
      userId: values.userId,
      permissions: []
    };

    for (const permission in values.permissions) {
      if (values.permissions[permission]) {
        params.permissions.push(permission);
      }
    }

    api
      .post(`/v2/devices/${device.id}/aces`, params)
      .then(() => {
        enqueueSnackbar("devices.settings.access.responses.created", {
          variant: "success"
        });

        onClose(true);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const handleSelectUser = user => {
    setUser(user);
  };

  if (isLoading) {
    return null;
  }

  return (
    <Dialog>
      {step === "user" && (
        <UserForm
          user={user}
          excludeList={excludeList}
          onUserSelected={handleSelectUser}
          onClose={onClose}
          onSubmit={() => setStep("permissions")}
        />
      )}
      {step === "permissions" && (
        <FormContainer
          userId={user.id}
          username={user.username}
          onClose={onClose}
          onSubmit={handleSubmit}
        />
      )}
    </Dialog>
  );
};

export default CreateContainer;
