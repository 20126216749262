import React, { useState, useEffect, useRef } from "react";
import { IconButton, makeStyles, ClickAwayListener } from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useHistory, useLocation } from "react-router-dom";

import { useDeviceIds } from "../../../domain/device";

const useIconStyles = makeStyles(theme => ({
  colorPrimary: {
    color: theme.palette.text.main
  },
  root: {
    padding: 2,
    paddingTop: 0
  }
}));

const useInputStyles = makeStyles(theme => ({
  input: {
    width: 75,
    border: 0,
    fontWeight: "bold",
    fontSize: 16,
    backgroundColor: theme.custom.deviceSideMenu.quickNav.backgroundColor,
    color: theme.palette.text.main,
    textAlign: "center",
    outline: "none"
  }
}));

const QuickNav = ({ device, subPage }) => {
  const inputRef = useRef();
  const { deviceIds } = useDeviceIds();
  const history = useHistory();
  const currentUrl = useLocation();

  const [originalId, setOriginalId] = useState(device.id);
  const [directAccessId, setDirectAccessId] = useState(device.id);

  const classes = {
    icon: useIconStyles(),
    input: useInputStyles()
  };

  useEffect(() => {
    setDirectAccessId(device.id);
    setOriginalId(device.id);
  }, [device.id]);

  const getFirstDeviceId = () => (deviceIds.length === 0 ? null : deviceIds[0]);

  const getPreviousDeviceId = () => {
    const idx = deviceIds.indexOf(device.id);
    if (idx <= 0) {
      return null;
    }

    return deviceIds[idx - 1];
  };

  const getNextDeviceId = () => {
    const idx = deviceIds.indexOf(device.id);
    if (idx === deviceIds.length - 1) {
      return null;
    }

    return deviceIds[idx + 1];
  };

  const buildUrl = deviceId => {
    if (subPage === "status") {
      return `/devices/${deviceId}`;
    }

    return currentUrl.pathname.replace(
      /\/devices\/\d+/,
      `/devices/${deviceId}`
    );
  };

  const handleBlur = () => {
    if (directAccessId === "") {
      setDirectAccessId(originalId);
    }
  };

  const getLastDeviceId = () =>
    deviceIds.length === 0 ? null : deviceIds[deviceIds.length - 1];

  const resetDirectAccessId = () => {
    setDirectAccessId("");

    setTimeout(() => {
      inputRef.current.focus();
    }, 50);
  };

  const updateDirectAccessId = value => {
    console.log("B");
    if (!value) {
      setDirectAccessId(device.id);
    }

    if (/^\d*$/.test(value)) {
      setDirectAccessId(value);
    }
  };

  const handleDirectAccessKeyDown = event => {
    if (event.keyCode !== 13) {
      return;
    }

    if (deviceIds.indexOf(parseInt(directAccessId, 10)) === -1) {
      return;
    }

    history.push(buildUrl(directAccessId));
  };

  const gotoFirstDevice = () => {
    const deviceId = deviceIds[0];

    history.push(buildUrl(deviceId));
  };

  const gotoLastDevice = () => {
    const deviceId = deviceIds[deviceIds.length - 1];

    history.push(buildUrl(deviceId));
  };

  const gotoPreviousDevice = () => {
    const previousDeviceId = getPreviousDeviceId();

    if (previousDeviceId === null) {
      return;
    }

    history.push(buildUrl(previousDeviceId));
  };

  const gotoNextDevice = () => {
    const nextDeviceId = getNextDeviceId();

    if (nextDeviceId === null) {
      return;
    }

    history.push(buildUrl(nextDeviceId));
  };

  return (
    <>
      <IconButton
        type="button"
        color="primary"
        classes={classes.icon}
        disabled={
          getFirstDeviceId() === null || getFirstDeviceId() === device.id
        }
        onClick={gotoFirstDevice}
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        type="button"
        color="primary"
        classes={classes.icon}
        disabled={getPreviousDeviceId() === null}
        onClick={gotoPreviousDevice}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <ClickAwayListener onClickAway={handleBlur}>
        <input
          className={classes.input.input}
          ref={inputRef}
          value={directAccessId}
          onChange={e => updateDirectAccessId(e.target.value)}
          onKeyDown={e => handleDirectAccessKeyDown(e)}
          onMouseDown={resetDirectAccessId}
          //onBlur={handleBlur}
          autoComplete="off"
          autoCapitalize="off"
          maxLength={5}
        />
      </ClickAwayListener>
      <IconButton
        type="button"
        color="primary"
        classes={classes.icon}
        disabled={getNextDeviceId() === null}
        onClick={gotoNextDevice}
      >
        <NavigateNextIcon />
      </IconButton>
      <IconButton
        type="button"
        color="primary"
        classes={classes.icon}
        disabled={getLastDeviceId() === null || getLastDeviceId() === device.id}
        onClick={gotoLastDevice}
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
};

export default QuickNav;
