import React from "react";
import { makeStyles, Button, Box } from "@material-ui/core";

import { useWhitelabel, WHITELABELS } from "../../domain/whitelabel";
import aconLogo from "../../assets/acon/header.png";
import aconLogo2x from "../../assets/acon/header@2x.png";

const useStyles = makeStyles(theme => ({
  titleOil: {
    fontSize: 20,
    textTransform: "uppercase",
    color: theme.palette.common.white
  },
  titleView: {
    fontSize: 20,
    textTransform: "uppercase",
    color: theme.palette.secondary.main
  },
  aconBox: {
    marginBottom: -8,
    "&:hover": {
      cursor: "pointer"
    }
  }
}));

const WhitelabeledTopBarLogo = ({ onClick }) => {
  const classes = useStyles();
  const { whitelabel } = useWhitelabel();

  if (whitelabel.name === WHITELABELS.ACON) {
    return (
      <Box className={classes.aconBox} onClick={onClick}>
        <img
          style={{ maxWidth: "100%" }}
          src={aconLogo}
          srcSet={`${aconLogo} 1x, ${aconLogo2x} 2x`}
          alt="acon"
        />
      </Box>
    );
  }

  return (
    <Button onClick={onClick}>
      <span className={classes.titleOil}>Oil</span>
      <span className={classes.titleView}>View</span>
    </Button>
  );
};

export default WhitelabeledTopBarLogo;
