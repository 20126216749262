import React from "react";
import { Box } from "@material-ui/core";

import SensorError from "./SensorError";
import SensorName from "./SensorName";
import SensorTemperature from "./SensorTemperature";
import { SENSOR_STATUS } from "../../../domain/sensor";
import SensorValue from "./SensorValue";

const Row = React.memo(({ sensor, sensorSettings }) => {
  return (
    <Box>
      {sensor.status === SENSOR_STATUS.ERROR ? (
        <SensorError sensor={sensor} />
      ) : (
        <SensorValue sensorSettings={sensorSettings} sensor={sensor} />
      )}
      {sensorSettings.showTemperatures && <SensorTemperature sensor={sensor} />}
      {sensorSettings.showNames && <SensorName name={sensor.name} />}
    </Box>
  );
});

export default Row;
