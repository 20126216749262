import React from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

import I18nText from "../../../elements/I18nText";
import { ListActionsWrapper, DeleteAction } from "../../../elements/ListAction";
import FormattedDate from "../../../elements/FormattedDate";

const List = React.memo(({ devices, onDelete }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box fontSize={16} fontWeight="bold">
            <I18nText>system.unassignedDevices.title</I18nText>
          </Box>
        </Grid>
      </Grid>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <I18nText>devices.common.identCode</I18nText>
            </TableCell>
            <TableCell>
              <I18nText>devices.common.id</I18nText>
            </TableCell>
            <TableCell>
              <I18nText>devices.common.host</I18nText>
            </TableCell>
            <TableCell>
              <I18nText>devices.common.lastReportedAt</I18nText>
            </TableCell>
            <TableCell style={{ minWidth: 75 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devices.map(device => (
            <TableRow key={device.id}>
              <TableCell>{device.identCode}</TableCell>
              <TableCell>{device.id}</TableCell>
              <TableCell>{device.host}</TableCell>
              <TableCell>
                <FormattedDate>{device.lastReportedAt}</FormattedDate>
              </TableCell>
              <TableCell align="right">
                <ListActionsWrapper>
                  <DeleteAction onClick={() => onDelete(device)} />
                </ListActionsWrapper>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
});

export default List;
