import React from "react";
import { CheckboxWithLabel } from "formik-material-ui";
import { FastField } from "formik";

import I18nText from "../../../../../../elements/I18nText";
import BubbleHelpFieldWrapper from "../../../../../../elements/FormField/BubbleHelpFieldWrapper";

const PublicUrlAccessEnabled = () => (
  <BubbleHelpFieldWrapper id="devices.edit.publicUrlAccessEnabled">
    <FastField
      component={CheckboxWithLabel}
      name="publicUrlAccessEnabled"
      type="checkbox"
      color="primary"
      Label={{
        label: (
          <I18nText>
            devices.settings.configuration.publicUrlAccessEnabled
          </I18nText>
        )
      }}
    />
  </BubbleHelpFieldWrapper>
);

export default PublicUrlAccessEnabled;
