import React from "react";
import { makeStyles, Box } from "@material-ui/core";

import { useWhitelabel, WHITELABELS } from "../../domain/whitelabel";

const useStyles = makeStyles({
  n: {
    lineHeight: "175%"
  },
  title: {
    fontWeight: 600,
    lineHeight: "175%"
  }
});

const WhitelabeledImprint = () => {
  const classes = useStyles();
  const { whitelabel } = useWhitelabel();

  // MOBENE
  if (whitelabel.name === WHITELABELS.MOBENE) {
    return (
      <Box>
        <Box className={classes.title}>
          Systembetreiber und Leistungspartner
        </Box>
        <Box className={classes.n}>
          TECSON GmbH &amp; Co. KG
          <br />
          Wulfsfelder Weg 2a
          <br />
          24242 Felde / Germany
          <br />
          Internet:{" "}
          <a
            href="https://www.tecson.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.tecson.de
          </a>
        </Box>
      </Box>
    );
  }

  // ACON
  if (whitelabel.name === WHITELABELS.ACON) {
    return (
      <Box>
        <Box className={classes.n}>
          ACON Energie Management GmbH
          <br />
          Zeltinger Platz 5<br />
          D-13465 Berlin
          <br />
          <br />
          Telefon: +49 30 40 60 75-0
          <br />
          Telefax: +49 30 40 60 75-10
          <br />
          E-Mail:{" "}
          <a href="mailto:info@acon-energie.de" rel="noopener noreferrer">
            info[at]acon-energie.de
          </a>
          <br />
          Web:{" "}
          <a
            href="https://www.acon-energie.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.acon-energie.de
          </a>
          <br />
          <br />
          Pflichtangaben:
          <br />
          Geschäftsführer: Markus Conrad
          <br />
          AG Charlottenburg: HRB 78692
          <br />
          Steuer-Nr.: 30/201/37529
          <br />
          USt-IdNr.: DE813122353
          <br />
          <br />
          Verantwortlicher im Sinne von § 55 Abs. 2 Rundfunkstaatsvertrag:
          <br />
          Markus Conrad
        </Box>
      </Box>
    );
  }

  // SMARTINSPECTOR
  if (whitelabel.name === WHITELABELS.SMARTINSPECTOR) {
    return (
      <Box>
        <Box className={classes.n} marginBottom={2}>
          GOK Regler- u. Armaturen Gesellschaft mbH &amp; Co. KG
          <br />
          Obernbreiter Straße 2-18
          <br />
          97340 Marktbreit / Germany
          <br />
          Internet:{" "}
          <a
            href="http://www.gok-online.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.gok-online.de
          </a>
        </Box>

        <Box className={classes.title}>
          Systembetreiber und Leistungspartner
        </Box>
        <Box className={classes.n}>
          TECSON GmbH &amp; Co. KG
          <br />
          Wulfsfelder Weg 2a
          <br />
          24242 Felde / Germany
          <br />
          Internet:{" "}
          <a
            href="https://www.tecson.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.tecson.de
          </a>
        </Box>
      </Box>
    );
  }

  // TECSON
  return (
    <Box>
      <Box className={classes.n} marginBottom={2}>
        <b>TECSON GmbH &amp; Co KG</b>
        <br />
        Wulfsfelder Weg 2a
        <br />
        D-24242 Felde (i. Holst.)
      </Box>

      <Box className={classes.n} marginBottom={2}>
        Tel.: (+49) 4340 - 40 25 30
        <br />
        Fax: (+49) 4340 - 40 25 29
        <br />
        Web:{" "}
        <a
          href="https://www.tecson.de"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.tecson.de
        </a>
        <br />
        Email: info (at) tecson.de
      </Box>

      <Box className={classes.n} marginBottom={2}>
        Handelsregister: HRA 8899 KI
        <br />
        Steuernummer: 20/285/44306
        <br />
        UST-ID / VAT ID: DE 298 763 956
        <br />
        Komplementärin: Tecson Apparate GmbH als persönlich haftende
        Gesellschafterin
        <br />
        Geschäftsführer: Thomas C. Petersen
      </Box>
    </Box>
  );
};

export default WhitelabeledImprint;
