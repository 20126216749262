import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { PrimaryButton } from "../../../../elements/Button";
import I18nText from "../../../../elements/I18nText";
import {
  ListActionsWrapper,
  EditAction,
  DeleteAction
} from "../../../../elements/ListAction";

import { ALIAS } from "../../../../domain/billingTerms";
import FormattedDate from "../../../../elements/FormattedDate";
import { FORMAT_DATE } from "../../../../utils/formatting";
import FormattedNumber from "../../../../elements/FormattedNumber";

const List = React.memo(
  ({ termsList, onEditTerms, onCreateTerms, onDeleteTerms }) => {
    const getName = terms => {
      if (terms.alias === ALIAS.CUSTOM) {
        return terms.custom.name;
      }

      return <I18nText>{`billing.aliases.${terms.alias}`}</I18nText>;
    };

    const getPrice = terms => {
      if (terms.price === null) {
        return "";
      }

      return (
        <>
          <FormattedNumber decimals={2}>{terms.price}</FormattedNumber>{" "}
          {terms.currency}
        </>
      );
    };

    const getInfo = terms => {
      if (
        terms.alias === ALIAS.SMS_DEVICE ||
        terms.alias === ALIAS.SMS_EXTERNAL
      ) {
        return (
          <I18nText values={{ count: terms.custom.exemptedQuantity }}>
            billing.common.smsIncluded
          </I18nText>
        );
      }

      return "";
    };

    const getChargingPeriod = terms => {
      return (
        <>
          <I18nText>{`billing.chargingPeriodTypes.${terms.chargingPeriodType}`}</I18nText>{" "}
          (
          <I18nText>{`billing.timesOfInvoicing.${terms.timeOfInvoicing}`}</I18nText>
          )
        </>
      );
    };

    return (
      <>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "right", marginTop: 16 }}>
            <PrimaryButton onClick={onCreateTerms} icon={<AddIcon />}>
              <I18nText>devices.settings.billing.actions.new</I18nText>
            </PrimaryButton>
          </Grid>
        </Grid>

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <I18nText>billing.common.type</I18nText>
              </TableCell>
              <TableCell align="right">
                <I18nText>billing.common.price</I18nText>
              </TableCell>
              <TableCell align="center">
                <I18nText>billing.common.chargingPeriodType</I18nText>
              </TableCell>
              <TableCell align="center">
                <I18nText>billing.common.invoicedUntil</I18nText>
              </TableCell>
              <TableCell align="center">
                <I18nText>billing.common.nextInvoicingAt</I18nText>
              </TableCell>
              <TableCell>
                <I18nText>billing.common.info</I18nText>
              </TableCell>
              <TableCell style={{ minWidth: 75 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {termsList.map(terms => (
              <TableRow key={terms.id}>
                <TableCell>{getName(terms)}</TableCell>
                <TableCell align="right">{getPrice(terms)}</TableCell>
                <TableCell align="center">{getChargingPeriod(terms)}</TableCell>
                <TableCell align="center">
                  <FormattedDate format={FORMAT_DATE} fallback="-">
                    {terms.invoicedUntil}
                  </FormattedDate>
                </TableCell>
                <TableCell align="center">
                  <FormattedDate format={FORMAT_DATE} fallback="-">
                    {terms.nextInvoicingAt}
                  </FormattedDate>
                </TableCell>
                <TableCell>{getInfo(terms)}</TableCell>

                <TableCell align="right">
                  <ListActionsWrapper>
                    <EditAction onClick={() => onEditTerms(terms)} />
                    <DeleteAction
                      disabled={terms.alias !== ALIAS.CUSTOM}
                      onClick={() => onDeleteTerms(terms)}
                    />
                  </ListActionsWrapper>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  }
);

export default List;
