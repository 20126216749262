import React from "react";
import {
  FormGroup,
  Box,
  FormControlLabel,
  Switch,
  Grid
} from "@material-ui/core";

import TableColumnCheckbox from "./TableColumnCheckbox";
import Section from "../../../elements/Section";
import SectionHeader from "../../../elements/SectionHeader";
import I18nText from "../../../elements/I18nText";
import {
  ButtonsWrapper,
  CancelButton,
  SaveButton
} from "../../../elements/Button";

const Config = React.memo(
  ({
    tableColumns,
    groupByUser,
    onToggleGroupByUser,
    onToggleTableColumn,
    sensorSettings,
    onToggleSensorSetting,
    onSave,
    onClose,
    isSubmitting
  }) => {
    const handleTableColumn = name => event => {
      onToggleTableColumn(name, event.target.checked);
    };

    const handleSensorSetting = name => event => {
      onToggleSensorSetting(name, event.target.checked);
    };

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Section>
              <SectionHeader>
                <I18nText>devices.list.config.sections.general</I18nText>
              </SectionHeader>
              <Grid container>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={groupByUser}
                        onChange={e => onToggleGroupByUser(e.target.checked)}
                        value="grouped"
                        color="primary"
                      />
                    }
                    label={
                      <I18nText>
                        devices.list.config.general.groupByUser
                      </I18nText>
                    }
                  />
                </Grid>
              </Grid>
              <Grid container>
                <SectionHeader>
                  <I18nText>devices.list.config.sections.sensors</I18nText>
                </SectionHeader>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={sensorSettings.showVisuals}
                        onChange={handleSensorSetting("showVisuals")}
                        value="showVisuals"
                        color="primary"
                      />
                    }
                    label={
                      <I18nText>
                        devices.list.config.sensors.showVisuals
                      </I18nText>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={sensorSettings.showNames}
                        onChange={handleSensorSetting("showNames")}
                        value="showNames"
                        color="primary"
                      />
                    }
                    label={
                      <I18nText>devices.list.config.sensors.showNames</I18nText>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={sensorSettings.showUnits}
                        onChange={handleSensorSetting("showUnits")}
                        value="showUnits"
                        color="primary"
                      />
                    }
                    label={
                      <I18nText>devices.list.config.sensors.showUnits</I18nText>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={sensorSettings.showTemperatures}
                        onChange={handleSensorSetting("showTemperatures")}
                        value="showTemperatures"
                        color="primary"
                      />
                    }
                    label={
                      <I18nText>
                        devices.list.config.sensors.showTemperatures
                      </I18nText>
                    }
                  />
                </Grid>
              </Grid>
            </Section>
          </Grid>
          <Grid item xs={12} md={6}>
            <Section>
              <SectionHeader>
                <I18nText>devices.list.config.sections.columns</I18nText>
              </SectionHeader>
              <FormGroup>
                <Grid container>
                  <Grid item xs={6}>
                    <Box>
                      <TableColumnCheckbox
                        name="id"
                        checked={tableColumns.indexOf("id") !== -1}
                        onUpdate={handleTableColumn("id")}
                      />
                    </Box>
                    <Box>
                      <TableColumnCheckbox
                        name="username"
                        checked={tableColumns.indexOf("username") !== -1}
                        onUpdate={handleTableColumn("username")}
                      />
                    </Box>
                    <Box>
                      <TableColumnCheckbox
                        name="name"
                        checked={tableColumns.indexOf("name") !== -1}
                        onUpdate={handleTableColumn("name")}
                      />
                    </Box>
                    <Box>
                      <TableColumnCheckbox
                        name="lastReportedAt"
                        checked={tableColumns.indexOf("lastReportedAt") !== -1}
                        onUpdate={handleTableColumn("lastReportedAt")}
                      />
                    </Box>
                    <Box>
                      <TableColumnCheckbox
                        name="status"
                        checked={tableColumns.indexOf("status") !== -1}
                        onUpdate={handleTableColumn("status")}
                      />
                    </Box>
                    <Box>
                      <TableColumnCheckbox
                        name="simCredit"
                        checked={tableColumns.indexOf("simCredit") !== -1}
                        onUpdate={handleTableColumn("simCredit")}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box>
                      <TableColumnCheckbox
                        name="countryCode"
                        checked={tableColumns.indexOf("countryCode") !== -1}
                        onUpdate={handleTableColumn("countryCode")}
                      />
                    </Box>
                    <Box>
                      <TableColumnCheckbox
                        name="postalCode"
                        checked={tableColumns.indexOf("postalCode") !== -1}
                        onUpdate={handleTableColumn("postalCode")}
                      />
                    </Box>
                    <Box>
                      <TableColumnCheckbox
                        name="city"
                        checked={tableColumns.indexOf("city") !== -1}
                        onUpdate={handleTableColumn("city")}
                      />
                    </Box>
                    <Box>
                      <TableColumnCheckbox
                        name="street"
                        checked={tableColumns.indexOf("street") !== -1}
                        onUpdate={handleTableColumn("street")}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </FormGroup>
            </Section>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ButtonsWrapper
              leftButton={
                <CancelButton onClick={onClose} disabled={isSubmitting} />
              }
              rightButton={
                <SaveButton onClick={onSave} disabled={isSubmitting} />
              }
            />
          </Grid>
        </Grid>
      </>
    );
  }
);

export default Config;
