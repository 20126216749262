import React from "react";
import {
  Typography,
  makeStyles,
  Box,
  CircularProgress
} from "@material-ui/core";
import clsx from "clsx";
import UpdateIcon from "@material-ui/icons/Update";

import FormattedDate from "../../../elements/FormattedDate";
import { DEVICE_TYPE, DEVICE_STATUS } from "../../../domain/device";
import I18nText from "../../../elements/I18nText";
import { PrimaryButton } from "../../../elements/Button";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: 63,
    borderBottom: "1px solid #00000029"
  },
  topColumn: {
    height: 32,
    display: "flex",
    alignItems: "flex-end",
    paddingBottom: 2
  },
  bottomColumn: {
    height: 31,
    display: "flex",
    alignItems: "flex-start",
    paddingTop: 2
  },
  singleColumn: {
    height: 63,
    display: "flex",
    alignItems: "center"
  },
  statusText: {
    fontSize: 20,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.custom.colors.deviceStatus.ok
  },
  statusAlarm: {
    color: theme.custom.colors.deviceStatus.alarm
  },
  metadata: {
    display: "flex",
    paddingLeft: 30,
    flex: "1 0"
  }
}));

const InfoBar = ({ device, allowedToQuery, isQuerying, onQueryDevice }) => {
  const classes = useStyles();

  const useBoldTextStyles = makeStyles({
    root: {
      fontSize: 13,
      fontWeight: "bold"
    }
  });

  const useTextStyles = makeStyles({
    root: {
      fontSize: 13
    }
  });

  const muiClasses = {
    text: useTextStyles(),
    boldText: useBoldTextStyles()
  };

  return (
    <div className={classes.container}>
      <div className={classes.metadata}>
        <Box flex={1} minWidth={275}>
          <div className={classes.topColumn}>
            <Typography noWrap={true} classes={muiClasses.boldText}>
              #{device.id}
              {device.name && ` - ${device.name}`}
            </Typography>
          </div>
          <div className={classes.bottomColumn}>
            <Typography noWrap={true} classes={muiClasses.text}>
              {device.address.street} {device.address.streetNumber},{" "}
              {device.address.countryCode}-{device.address.postalCode}{" "}
              {device.address.city}
            </Typography>
          </div>
        </Box>
        <Box flex={2} paddingLeft={1}>
          <div className={classes.topColumn}>
            <Typography noWrap={true} classes={muiClasses.boldText}>
              {device.user.name}
            </Typography>
          </div>
          <div className={classes.bottomColumn}>
            <Typography noWrap={true} classes={muiClasses.text}>
              <FormattedDate fallback="-">
                {device.lastReportedAt}
              </FormattedDate>
            </Typography>
          </div>
        </Box>
      </div>
      <Box flex="0 1" paddingRight={4}>
        <div className={classes.singleColumn}>
          <Typography
            noWrap={true}
            className={clsx(classes.statusText, {
              [classes.statusAlarm]: device.status === DEVICE_STATUS.ALARM
            })}
          >
            {device.status}
          </Typography>
        </div>
      </Box>
      {device.type === DEVICE_TYPE.SMS && device.ident && allowedToQuery && (
        <Box flex="0 1">
          <div
            className={classes.singleColumn}
            style={{
              selfAlign: "flex-end",
              justifyContent: "flex-end",
              paddingRight: 20
            }}
          >
            {isQuerying ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress color="primary" size={20} thickness={6} />
              </Box>
            ) : (
              <PrimaryButton icon={<UpdateIcon />} onClick={onQueryDevice}>
                <I18nText>devices.infoBar.query.action</I18nText>
              </PrimaryButton>
            )}
          </div>
        </Box>
      )}
    </div>
  );
};

export default InfoBar;
