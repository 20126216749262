import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

import WhitelabelContext from "./WhitelabelContext";
import { useApi } from "../api";
import { useAuth } from "../auth";
import { useObjectConfig } from "../objectConfig";
import oilviewTheme from "../themes/oilview/theme";
import optilogTheme from "../themes/optilog/theme";
import aconTheme from "../themes/acon/theme";
import { WHITELABELS } from "../domain/whitelabel";

const WhitelabelProvider = ({ children }) => {
  const api = useApi();
  const { user } = useAuth();
  const { set: setConfig } = useObjectConfig();

  const [isLoading, setLoading] = useState(true);
  const [whitelabel, setWhitelabel] = useState(null);
  const [whitelabelConfig, setWhitelabelConfig] = useState({});
  const [whitelabelConfigLoaded, setWhitelabelConfigLoaded] = useState(false);
  const [reload, doReload] = useState(false);

  // Load current whitelabel
  useEffect(() => {
    setLoading(true);

    api.get("/v2/whitelabels/current").then(response => {
      setWhitelabel(response.data);
      setLoading(false);

      // Set Favicons and HTML Title
      const faviconElem = document.createElement("link");
      faviconElem.rel = "icon";
      document.head.appendChild(faviconElem);

      switch (response.data.name) {
        case WHITELABELS.ACON:
          faviconElem.href = "acon/favicon.png";
          document.title = "Acon | Qualität in Energie";

          const appleIcon = document.createElement("link");
          appleIcon.rel = "apple-touch-icon";
          appleIcon.href = "acon/apple-icon.png";
          document.head.appendChild(appleIcon);

          break;

        default:
          faviconElem.ref = "favicon.ico";
          document.title = "Oilview";

          break;
      }
    });
  }, [api]);

  const reloadWhitelabelConfig = () => doReload(true);

  const updateWhitelabelConfig = (key, value) => {
    setWhitelabelConfig(config => ({
      ...config,
      [key]: value
    }));

    setConfig([
      {
        obj: "whitelabel",
        id: whitelabel.id,
        key,
        value
      }
    ]);
  };

  // Load whitelabel config
  useEffect(() => {
    if (user !== null) {
      doReload(true);
    }
  }, [user]);

  useEffect(() => {
    async function fetchConfig() {
      doReload(false);

      const response = await api.get("/v2/config/whitelabel");
      setWhitelabelConfig(response.data);
      setWhitelabelConfigLoaded(true);
    }

    if (reload) {
      fetchConfig();
    }
  }, [api, reload]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        width="100vw"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  let theme;
  switch (whitelabel.name) {
    case WHITELABELS.OPTILOG:
      theme = optilogTheme;
      break;

    case WHITELABELS.ACON:
      theme = aconTheme;
      break;

    default:
      theme = oilviewTheme;
  }

  return (
    <WhitelabelContext.Provider
      value={{
        whitelabel,
        whitelabelConfig,
        whitelabelConfigLoaded,
        reloadWhitelabelConfig,
        updateWhitelabelConfig
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </WhitelabelContext.Provider>
  );
};

export default WhitelabelProvider;
