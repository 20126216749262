import React, { useCallback, useState } from "react";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import { Grid, Box } from "@material-ui/core";

import { useDevice } from "../../../../domain/device";
import MetadataDialog from "./BaseData/MetadataDialog";
import SectionHeader from "../../../../elements/SectionHeader";
import I18nText from "../../../../elements/I18nText";
import DescriptionList from "../../../../elements/DescriptionList";
import Section from "../../../../elements/Section";
import RefDateQueries from "./RefDateQueries";
import { nl2br } from "../../../../utils/stringUtils";
import { useAuth, ROLES, PERMISSIONS } from "../../../../auth";
import Comment from "./Comment";
import MasterComment from "./MasterComment";
import Delete from "./Delete";
import { DeleteButton } from "../../../../elements/Button";

const Metadata = () => {
  const { device, reload } = useDevice();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { user, hasRole, hasPermission } = useAuth();

  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleClose = doReload => {
    history.push(url);

    doReload && reload();
  };

  const handleDelete = () => {
    setConfirmDelete(true);
  };

  const handleDeleted = () => {
    history.push("/devices");
  };

  const editMetadata = () =>
    history.push(`/devices/${device.id}/settings/metadata/edit`);

  const editComment = () =>
    history.push(`/devices/${device.id}/settings/metadata/comment`);

  const editMasterComment = () =>
    history.push(`/devices/${device.id}/settings/metadata/master-comment`);

  const getGeneralOptions = useCallback(() => {
    const options = [
      {
        label: "devices.settings.metadata.user",
        value: device.user.username
      },
      {
        label: "devices.common.name",
        value: device.name
      }
    ];

    return options;
  }, [device]);

  const getAddressOptions = useCallback(() => {
    const options = [
      {
        label: "devices.settings.metadata.street",
        value: `${device.address.street} ${device.address.streetNumber}`
      },
      {
        label: "devices.settings.metadata.postalCode",
        value: device.address.postalCode
      },
      {
        label: "devices.settings.metadata.city",
        value: device.address.city
      },
      {
        label: "devices.settings.metadata.countryCode",
        value: device.address.countryCode ? (
          <I18nText>{`countries.${device.address.countryCode}`}</I18nText>
        ) : (
          ""
        )
      }
    ];

    return options;
  }, [device]);

  return (
    <>
      {confirmDelete && (
        <Delete
          device={device}
          onCancel={() => setConfirmDelete(false)}
          onDeleted={handleDeleted}
        />
      )}
      <Switch>
        <Route path={`${url}/edit`}>
          <MetadataDialog device={device} onClose={handleClose} />
        </Route>
        <Route path={`${url}/comment`}>
          <Comment device={device} onClose={handleClose} />
        </Route>
        <Route path={`${url}/master-comment`}>
          <MasterComment device={device} onClose={handleClose} />
        </Route>
      </Switch>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Section>
            <Box marginTop={2}>
              <SectionHeader
                onEdit={editMetadata}
                disableEdit={
                  !hasPermission(device.permissions, PERMISSIONS.CONFIGURATION)
                }
              >
                <I18nText>devices.settings.metadata.sections.baseData</I18nText>
              </SectionHeader>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <DescriptionList options={getGeneralOptions()} />
              </Grid>
              <Grid item xs={12} md={6}>
                <DescriptionList options={getAddressOptions()} />
              </Grid>
            </Grid>
          </Section>
        </Grid>
      </Grid>

      {hasPermission(device.permissions, PERMISSIONS.EDIT) && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Section>
              <SectionHeader>
                <I18nText>
                  devices.settings.metadata.sections.refDateQueries
                </I18nText>
              </SectionHeader>
              <RefDateQueries deviceId={device.id} />
            </Section>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Section>
            <SectionHeader
              onEdit={editComment}
              disableEdit={
                !hasPermission(device.permissions, PERMISSIONS.CONFIGURATION)
              }
            >
              <I18nText>devices.settings.metadata.sections.comment</I18nText>
            </SectionHeader>
            {nl2br(device.comment)}
          </Section>
        </Grid>

        {hasRole(user, ROLES.MASTER) && (
          <Grid item xs={12} md={6}>
            <Section>
              <SectionHeader onEdit={editMasterComment}>
                <I18nText>
                  devices.settings.metadata.sections.masterComment
                </I18nText>
              </SectionHeader>
              {nl2br(device.masterComment)}
            </Section>
          </Grid>
        )}
      </Grid>

      {hasRole(user, ROLES.MASTER) && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Section>
              <SectionHeader />
              <Box display="flex" justifyContent="flex-end">
                <DeleteButton onClick={handleDelete}></DeleteButton>
              </Box>
            </Section>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Metadata;
