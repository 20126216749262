import React, { memo } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Box, LinearProgress } from "@material-ui/core";

import NoData from "./NoData";
import { PrimaryButton } from "../../../elements/Button";
import I18nText from "../../../elements/I18nText";

const Consumption = memo(
  ({
    noData,
    isLoading,
    onExport,
    isExporting,
    LegendComponent,
    ChartComponent,
    TableComponent
  }) => {
    if (isLoading) {
      return (
        <Box marginTop={2}>
          <LinearProgress color="secondary" />
        </Box>
      );
    }

    if (noData) {
      return (
        <>
          <NoData />
          {LegendComponent}
        </>
      );
    }

    return (
      <>
        {ChartComponent}

        {LegendComponent}
        <Box marginTop={2}>{TableComponent}</Box>

        <Box display="flex" justifyContent="flex-end" marginTop={2}>
          <PrimaryButton
            disabled={isExporting}
            onClick={onExport}
            icon={<GetAppIcon />}
          >
            {isExporting ? (
              <I18nText>devices.consumption.export.button.started</I18nText>
            ) : (
              <I18nText>devices.consumption.export.button.export</I18nText>
            )}
          </PrimaryButton>
        </Box>
      </>
    );
  }
);

export default Consumption;
