import React from "react";
import { TableCell, Box, TableRow, makeStyles } from "@material-ui/core";
import clsx from "clsx";

import DeviceStatus from "./DeviceStatus";
import Sensor from "./Sensor";
import FormattedDate from "../../elements/FormattedDate";
import useFormat from "../../hooks/useFormat";
import { DEVICE_STATUS } from "../../domain/device";

const useStyles = makeStyles(theme => ({
  cell: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  cellError: {
    color: theme.palette.error.main
  },
  centerAligned: {
    textAlign: "center"
  }
}));

const Row = React.memo(({ device, onSelect, showColumn, sensorSettings }) => {
  const { formatNumber } = useFormat();
  const classes = useStyles();

  const cellClasses = clsx(classes.cell, {
    [classes.cellError]: device.status !== DEVICE_STATUS.OK
  });

  const connectedSensors = device.sensors.filter(sensor => sensor.isConnected);

  return (
    <TableRow hover key={device.id} onClick={() => onSelect(device.id)}>
      {showColumn("id") && (
        <TableCell className={cellClasses} scope="row">
          {device.id}
        </TableCell>
      )}
      {showColumn("username") && (
        <TableCell className={cellClasses}>{device.user.name}</TableCell>
      )}
      {showColumn("name") && (
        <TableCell className={cellClasses}>{device.name}</TableCell>
      )}
      {showColumn("countryCode") && (
        <TableCell className={cellClasses}>
          {device.address.countryCode}
        </TableCell>
      )}
      {showColumn("postalCode") && (
        <TableCell className={cellClasses}>
          {device.address.postalCode}
        </TableCell>
      )}
      {showColumn("city") && (
        <TableCell className={cellClasses}>{device.address.city}</TableCell>
      )}
      {showColumn("street") && (
        <TableCell className={cellClasses}>
          {device.address.street} {device.address.streetNumber}
        </TableCell>
      )}
      {showColumn("lastReportedAt") && (
        <TableCell className={cellClasses}>
          <FormattedDate>{device.lastReportedAt}</FormattedDate>
        </TableCell>
      )}
      {showColumn("simCredit") && (
        <TableCell className={cellClasses} align="right">
          {device.simCreditQty && formatNumber(device.simCreditQty, 2)}
        </TableCell>
      )}
      {showColumn("status") && (
        <TableCell className={cellClasses} align="center">
          <DeviceStatus device={device} />
        </TableCell>
      )}

      <TableCell className={classes.cell}>
        <Box display="flex">
          {connectedSensors.map(sensor => (
            <Box width="25%" paddingLeft={1} key={sensor.id}>
              <Sensor sensor={sensor} sensorSettings={sensorSettings} />
            </Box>
          ))}
        </Box>
      </TableCell>
    </TableRow>
  );
});

export default Row;
