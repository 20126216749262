import React, { useState, useEffect } from "react";

import Dialog from "./Dialog";
import { useApi } from "../../../../../api";
import useI18nSnackbar from "../../../../../hooks/useI18nSnackbar";
import { format } from "date-fns";
import { ALIAS } from "../../../../../domain/billingTerms";
import TermsForm from "../Form/TermsForm";

const EditContainer = ({ device, termsId, onClose }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [isLoading, setLoading] = useState(true);
  const [terms, setTerms] = useState(null);

  useEffect(() => {
    setLoading(true);

    api
      .get(`/v2/billing-terms/${termsId}`)
      .then(response => {
        setTerms(response.data);
        setLoading(false);
      })
      .catch(e => {
        if (e.response && e.response.status === 404) {
          onClose();
        }
      });
  }, [api, device, termsId, onClose]);

  const handleSubmit = (values, { setSubmitting }) => {
    const params = {
      price: values.price,
      chargingPeriodType: values.chargingPeriodType,
      timeOfInvoicing: values.timeOfInvoicing,
      validFrom: values.validFrom
        ? format(new Date(values.validFrom), "yyyy-MM-dd")
        : null,
      validUntil: values.validUntil
        ? format(new Date(values.validUntil), "yyyy-MM-dd")
        : null,
      invoicedUntil: values.invoicedUntil
        ? format(new Date(values.invoicedUntil), "yyyy-MM-dd")
        : null
    };

    if (terms.alias === ALIAS.CUSTOM) {
      params.name = values.custom.name;
    }

    if (
      terms.alias === ALIAS.SMS_DEVICE ||
      terms.alias === ALIAS.SMS_EXTERNAL
    ) {
      params.exemptedQuantity = values.custom.exemptedQuantity;
    }

    api
      .put(`/v2/billing-terms/${termsId}`, params)
      .then(() => {
        enqueueSnackbar("devices.settings.billing.responses.updated", {
          variant: "success"
        });

        onClose(true);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  if (isLoading) {
    return null;
  }

  const values = {
    ...terms,
    price: terms.price ? terms.price.toFixed(2) : ""
  };

  return (
    <Dialog>
      <TermsForm
        isEdit={true}
        values={values}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default EditContainer;
