import React from "react";

export const REGEXP_ID = "\\d+";

export const toCamel = s => {
  return s.replace(/([-_][a-z])/gi, $1 => {
    return $1
      .toUpperCase()
      .replace("-", "")
      .replace("_", "");
  });
};

const toSnake = s => {
  return s
    .replace(/[\w]([A-Z])/g, m => {
      return m[0] + "_" + m[1];
    })
    .toLowerCase();
};

const isObject = o => {
  return o === Object(o) && !Array.isArray(o) && typeof o !== "function";
};

export const keysToCamel = o => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach(k => {
      n[toCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  } else if (Array.isArray(o)) {
    return o.map(i => {
      return keysToCamel(i);
    });
  }

  return o;
};

export const keysToSnake = o => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach(k => {
      n[toSnake(k)] = keysToSnake(o[k]);
    });

    return n;
  } else if (Array.isArray(o)) {
    return o.map(i => keysToSnake(i));
  }

  return o;
};

export const nl2br = text => {
  if (!text) {
    return "";
  }

  const newlineRegex = /(\r\n|\r|\n)/g;

  return text.split(newlineRegex).map(function(line, index) {
    if (line.match(newlineRegex)) {
      return React.createElement("br", { key: index });
    }
    return line;
  });
};

export const generatePassword = (len = 16) => {
  let pass = "";
  const str =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$";

  for (let i = 1; i <= len; i++) {
    const char = Math.floor(Math.random() * str.length + 1);

    pass += str.charAt(char);
  }

  return pass;
};
