import React, { useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";

import { useApi } from "../../../api";
import List from "./List";
import Delete from "./Delete";

const ListContainer = () => {
  const api = useApi();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [devices, setDevices] = useState([]);
  const [reload, setReload] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [confirmDeleteDevice, setConfirmDeleteDevice] = useState(null);

  useEffect(() => {
    if (!reload) {
      return;
    }

    setReload(false);
    setLoading(true);

    api
      .get("/v2/devices/unassigned")
      .then(response => {
        setDevices(response.data);
        setLoading(false);
      })
      .catch(() => {})
      .then(() => setReload(false));
  }, [api, reload]);

  const handleDelete = device => {
    setConfirmDeleteDevice(device);
  };

  const handleDeleted = () => {
    setReload(true);
    setConfirmDeleteDevice(null);
    history.push(url);
  };

  return (
    <>
      {confirmDeleteDevice && (
        <Delete
          device={confirmDeleteDevice}
          onCancel={() => setConfirmDeleteDevice(null)}
          onDeleted={handleDeleted}
        />
      )}
      <List isLoading={isLoading} devices={devices} onDelete={handleDelete} />
    </>
  );
};

export default ListContainer;
