import React, { useCallback } from "react";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import { useDevice } from "../../../../domain/device";
import ConfigurationDialog from "./edit/ConfigurationDialog";
import { PrimaryButton } from "../../../../elements/Button";
import SectionHeader from "../../../../elements/SectionHeader";
import I18nText from "../../../../elements/I18nText";
import DescriptionList from "../../../../elements/DescriptionList";
import { DEVICE_TYPE } from "../../../../domain/device";
import { useSmsGateways } from "../../../../domain/smsGateway";
import { inMostSuitableTimeUnit } from "../../../../utils/timeUtils";
import SensorTable from "./SensorTable";
import Section from "../../../../elements/Section";
import { useAuth, PERMISSIONS, ROLES } from "../../../../auth";
import FormattedIdent from "../../../../elements/FormattedIdent";

const Configuration = () => {
  const { t } = useTranslation();
  const { device, reload } = useDevice();
  const { user, hasRole, hasPermission } = useAuth();
  const history = useHistory();
  const { url } = useRouteMatch();
  const smsGateways = useSmsGateways();

  const handleClose = doReload => {
    history.push(url);

    doReload && reload();
  };

  const editConfiguration = () =>
    history.push(`/devices/${device.id}/settings/configuration/edit`);

  const connectedSensors = device.sensors.filter(sensor => sensor.isConnected);

  const getDeviceOptions = useCallback(() => {
    const options = [
      {
        label: "devices.common.type",
        value: (
          <I18nText>{`devices.common.typeOptions.${device.type}`}</I18nText>
        )
      }
    ];

    if (device.type === DEVICE_TYPE.SMS || device.type === DEVICE_TYPE.IOT) {
      options.push({
        label: `devices.common.identTypes.${device.identType}`,
        value: (
          <FormattedIdent type={device.identType}>
            {device.ident}
          </FormattedIdent>
        )
      });
    }

    if (device.type === DEVICE_TYPE.NET) {
      options.push({
        label: "devices.common.identCode",
        value: device.ident
      });

      options.push({
        label: "devices.common.host",
        value: `${device.host}${device.port ? ":" + device.port : ""}`
      });
    }

    options.push({
      label: "devices.common.firmwareVersion",
      value: device.firmwareVersion
    });

    options.push({
      label: "devices.common.hardwareVersion",
      value: device.hardwareVersion
    });

    if (hasRole(user, ROLES.MASTER) && device.publicUrlAccessEnabled) {
      options.push({
        label: "devices.common.publicUrl",
        value: (
          <a href={device.publicUrl} target="_blank" rel="noopener noreferrer">
            <OpenInNewIcon style={{ fontSize: 12 }} />{" "}
            <I18nText>
              devices.settings.configuration.publicUrlOpenInNewTab
            </I18nText>
          </a>
        )
      });
    }

    return options;
  }, [device, hasRole, user]);

  const getSmsDeviceOptions = useCallback(() => {
    const getSmsGatewayById = id =>
      smsGateways.find(gateway => gateway.id === id);

    const options = [
      {
        label: "devices.settings.configuration.simType",
        value: device.simType ? (
          <I18nText>{`devices.settings.configuration.simTypeOptions.${device.simType}`}</I18nText>
        ) : (
          ""
        )
      }
    ];

    if (device.simProvider) {
      options.push({
        label: "devices.settings.configuration.simProvider",
        value: device.simProvider ? (
          <I18nText>{`mobileProviders.${device.simProvider}`}</I18nText>
        ) : (
          ""
        )
      });
    }

    const inboundGateway = getSmsGatewayById(device.inboundGatewayId);

    if (inboundGateway) {
      options.push({
        label: "devices.settings.configuration.inboundGatewayId",
        value: (
          <>
            {inboundGateway.label} (
            <I18nText>{`mobileProviders.${inboundGateway.provider}`}</I18nText>)
          </>
        )
      });
    }

    const outboundGateway = getSmsGatewayById(device.outboundGatewayId);

    if (outboundGateway) {
      options.push({
        label: "devices.settings.configuration.outboundGatewayId",
        value: (
          <>
            {outboundGateway.label} (
            <I18nText>{`mobileProviders.${outboundGateway.provider}`}</I18nText>
            )
          </>
        )
      });
    }

    return options;
  }, [device, smsGateways]);

  const getReportingOptions = useCallback(() => {
    const timeInterval = inMostSuitableTimeUnit(device.timeInterval);
    const thresholdInterval = inMostSuitableTimeUnit(device.thresholdInterval);

    if (device.type === DEVICE_TYPE.NET) {
      return [
        {
          label:
            "devices.settings.configuration.sections.thresholdNotifications",
          value: (
            <I18nText
              values={{
                thresholdSensor: t(
                  `devices.settings.configuration.thresholdSensorOptions.${device.thresholdSensor}`
                ),
                derived: device.thresholdDerived
              }}
            >
              devices.settings.configuration.thresholdNotificationsNet
            </I18nText>
          )
        }
      ];
    }

    return [
      {
        label: "devices.settings.configuration.smsHeader",
        value: device.smsHeader
      },

      {
        label: "devices.settings.configuration.sections.timeBasedNotifications",
        value: (
          <I18nText
            values={{
              valueInterval: device.valueInterval,
              timeIntervalValue: timeInterval.value,
              timeIntervalUnit: t(`date.timeUnits.${timeInterval.unit}`)
            }}
          >
            devices.settings.configuration.timeBasedNotifications
          </I18nText>
        )
      },
      {
        label: "devices.settings.configuration.sections.thresholdNotifications",
        value: (
          <I18nText
            values={{
              thresholdSensor: t(
                `devices.settings.configuration.thresholdSensorOptions.${device.thresholdSensor}`
              ),
              derived: device.thresholdDerived,
              thresholdIntervalValue: thresholdInterval.value,
              thresholdIntervalUnit: t(
                `date.timeUnits.${thresholdInterval.unit}`
              )
            }}
          >
            devices.settings.configuration.thresholdNotifications
          </I18nText>
        )
      }
    ];
  }, [device, t]);

  return (
    <>
      <Switch>
        <Route path={`${url}/edit`}>
          <ConfigurationDialog device={device} onClose={handleClose} />
        </Route>
      </Switch>
      <Grid>
        {hasPermission(device.permissions, PERMISSIONS.CONFIGURATION) && (
          <Grid container>
            <Grid item xs={12} style={{ textAlign: "right", marginTop: 16 }}>
              <PrimaryButton onClick={editConfiguration} icon={<EditIcon />}>
                <I18nText>actions.edit</I18nText>
              </PrimaryButton>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Section>
              <SectionHeader>
                <I18nText>
                  devices.settings.configuration.sections.sensors
                </I18nText>
              </SectionHeader>
              <Grid container>
                <Grid item xs={12}>
                  <SensorTable sensors={connectedSensors} />
                </Grid>
              </Grid>
            </Section>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Section>
                  <SectionHeader>
                    <I18nText>
                      devices.settings.configuration.sections.device
                    </I18nText>
                  </SectionHeader>
                  <DescriptionList options={getDeviceOptions()} />
                </Section>
              </Grid>
              {device.type === DEVICE_TYPE.SMS && (
                <Grid item xs={12}>
                  <Section>
                    <SectionHeader>
                      <I18nText>
                        devices.settings.configuration.sections.smsDevice
                      </I18nText>
                    </SectionHeader>
                    <DescriptionList options={getSmsDeviceOptions()} />
                  </Section>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Section>
              <SectionHeader>
                <I18nText>
                  devices.settings.configuration.sections.reporting
                </I18nText>
              </SectionHeader>
              <DescriptionList options={getReportingOptions()} />
            </Section>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Configuration;
