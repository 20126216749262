import React from "react";
import { Box, useTheme } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

import { DEVICE_STATUS } from "../../domain/device";
import I18nText from "../../elements/I18nText";
import { DisregardIcon, SeenIcon } from "../../elements/Icon";

const DeviceStatus = ({ device }) => {
  const theme = useTheme();

  if (device.status === DEVICE_STATUS.ALARM) {
    const statuses = [];

    device.alarms.forEach(alarm => {
      if (!alarm.confirmationStatus) {
        statuses.push(DEVICE_STATUS.ALARM);

        return;
      }

      statuses.push(alarm.confirmationStatus);
    });

    if (statuses.indexOf(DEVICE_STATUS.ALARM) !== -1) {
      return <I18nText>devices.list.table.statuses.alarm</I18nText>;
    }

    if (statuses.indexOf("seen") !== -1) {
      return <SeenIcon />;
    }

    if (statuses.indexOf("disregard") !== -1) {
      return <DisregardIcon />;
    }
  }

  if (device.thresholdStatus) {
    if (device.thresholdConfirmationStatus === "seen") {
      return (
        <Box color={theme.palette.warning.main}>
          <SeenIcon />
        </Box>
      );
    }

    if (device.thresholdConfirmationStatus === "disregard") {
      return (
        <Box color={theme.palette.warning.main}>
          <DisregardIcon />
        </Box>
      );
    }

    return (
      <Box color={theme.palette.error.main}>
        <I18nText>devices.list.table.statuses.threshold</I18nText>
      </Box>
    );
  }

  if (device.status === DEVICE_STATUS.OK) {
    return (
      <Box color={theme.palette.success.main}>
        <CheckIcon fontSize="small" />
      </Box>
    );
  }

  return "???";
};

export default DeviceStatus;
