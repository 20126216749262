import React from "react";
import { Grid } from "@material-ui/core";

import ThingName from "./ThingName";

const IotDevice = () => {
  return (
    <Grid container>
      <Grid item xs={12} md={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ThingName />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IotDevice;
