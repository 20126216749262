import React from "react";

import I18nText from "../../../elements/I18nText";
import UnassignedDeviceSelect from "../../../elements/FormField/UnassignedDeviceSelect";

const NetDeviceForm = ({ value, onSelect, hasError }) => {
  return (
    <UnassignedDeviceSelect
      name="netDevice"
      required={true}
      value={value}
      hasError={hasError}
      onUpdate={value => onSelect(value)}
      label={<I18nText>devices.create.selectNetDevice</I18nText>}
    />
  );
};

export default NetDeviceForm;
