import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useApi } from "../../api";
import I18nText from "../I18nText";

const UnassignedDeviceSelect = React.memo(
  ({ value, name, label, hasError, required, onUpdate }) => {
    const api = useApi();
    const [options, setOptions] = useState([]);

    useEffect(() => {
      let mounted = true;

      const query = `/v2/devices/unassigned`;

      api
        .get(query)
        .then(response => {
          if (!mounted) {
            return;
          }

          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          const results = response.data.map(device => ({
            id: device.id,
            label: device.identCode
          }));

          setOptions([...newOptions, ...results]);
        })
        .catch(() => {});

      return () => {
        mounted = false;
      };
    }, [api, value]);

    return (
      <Autocomplete
        id="unassigned-device-select"
        variant="outlined"
        name={name}
        autoSelect={true}
        filterOptions={x => x}
        value={value}
        noOptionsText={<I18nText>devices.selector.noOptions</I18nText>}
        options={options}
        onChange={(_, value) => onUpdate(value)}
        getOptionLabel={option => option.label}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={params => (
          <TextField
            error={!!hasError}
            {...params}
            required={required}
            label={label}
            variant="outlined"
            size="small"
            style={{ width: "100%" }}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off"
            }}
          />
        )}
      />
    );
  }
);

export default UnassignedDeviceSelect;
